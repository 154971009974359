<template>
  <div class="be-card h-100">
    <header-with-divider
      :text="$t('transaction.transfers_list')"
      color="blue"
    />
    <div
      class="d-flex align-items-center justify-content-between p-4 contentTop"
    >
      <BaseInputSearch @search="search" />
      <button
        :class="{
          'i-left': !smallSize,
          'circle-btn': smallSize,
        }"
        class="be-btn primary green lg"
        @click="$router.push({ name: 'transfer.form' })"
      >
        <i class="icon-plus" />
        {{ !smallSize ? $t("transaction.new_transfer") : "" }}
      </button>
    </div>
    <div class="divider" />
    <loading v-if="loading" />
    <div
      v-else-if="!loading && transfers && transfers.length"
      class="scroll-wrapper"
    >
      <be-table
        :data="transfers"
        :fields="smallSize ? smallTableFields : fields"
        :smallSize="smallSize"
        rowClasses="py-3 mh-40"
      >
        <template v-slot:status="{ row }">
          <span
            :class="[
              smallSize
                ? statusColorClass(row.status)
                : statusClass(row.status),
              smallSize ? '' : 'status',
            ]"
          >
            {{ row.status }}
          </span>
        </template>
        <template v-slot:created_at="{ row }">
          <div v-if="smallSize">
            {{ row.created_at | moment("Do MMMM YYYY H:mm:ss") }}
          </div>
          <div v-else class="d-flex flex-column">
            <span>{{ row.created_at | moment("Do MMMM YYYY") }}</span>
            <small>{{ row.created_at | moment("H:mm:ss") }}</small>
          </div>
        </template>
        <template v-slot:amount="{ row }">
          <div class="d-flex flex-column f-w-600">
            <span>{{ row.amount + " " + row.currency }}</span>
            <span v-if="!smallSize" class="f-12">{{ row.status }}</span>
          </div>
        </template>
        <template v-slot:montant-status="{ row }">
          <div class="d-flex flex-column f-w-600">
            <span :class="[statusColorClass(row.status)]">{{
              row.status
            }}</span>
          </div>
        </template>
        <template v-slot:desc="{ row }">
          <div class="d-flex flex-column f-w-600">
            <span class="f-14">{{ description(row).title }}</span>
            <span class="f-10 description text-truncate">{{
              description(row).email
            }}</span>
          </div>
        </template>
        <template v-slot:reference="{ row }">
          <div class="d-flex flex-column">
            {{ row.reference }}
          </div>
        </template>
        <template v-slot:actions="{ row }">
          <div @click.stop="openModal(row)">
            <i :class="smallSize && 'd-block'" class="icon-arrow-right" />
          </div>
        </template>
      </be-table>
      <BasePagination
        v-if="transfers && transfers.length && totalPages > 1 && !loading"
        :current_page="current_page"
        :last_page="last_page"
        @page="getTransferList"
      />
    </div>
    <no-content
      v-else
      :text="$t('transaction.no_transaction_at_the_moment')"
      icon="icon-transfer"
    ></no-content>
    <b-modal ref="detailsModal" no-fade size="lg" top>
      <div class="text-center">
        <b-row>
          <b-col>
            <div class="label-text">
              <div>{{ $t("transaction.sender") }}</div>
              <div>{{ modalData && modalData.sender.nom }}</div>
            </div>
          </b-col>
          <b-col>
            <div class="label-text">
              <div>{{ $t("transaction.receiver_1") }}</div>
              <div>{{ modalData && modalData.receiver.nom }}</div>
            </div>
          </b-col>
          <b-col>
            <div class="label-text">
              <div>{{ $t("transaction.receiver_1_email") }}</div>
              <div>
                {{ modalData && modalData.receiver.email }}
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <div class="label-text">
              <div>{{ $t("common.status") }}</div>
              <div :class="modalData && statusColorClass(modalData.status)">
                {{ modalData && modalData.status }}
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="label-text">
              <div>{{ $t("common.creation_date") }}</div>
              <div>
                {{ modalData && modalData.created_at }}
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="label-text">
              <div>{{ $t("transaction.received_amount") }}</div>
              <div>
                {{ modalData && `${modalData.amount} ${modalData.currency}` }}
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <template slot="modal-header">
        <span />
        <span class="modal-title">
          {{ $t("transaction.transfer_details") }} -
          {{ modalData && modalData.reference }}
        </span>
        <span @click="hideModal">
          <i class="icon-close f-24" />
        </span>
      </template>
      <template slot="modal-footer">
        <template
          v-if="
            modalData &&
              (modalData.status === 'Echec' || modalData.status === 'En cours')
          "
        >
          <span class="f-w-600 fermer mr-4" @click="hideModal">
            {{ $t("common.close") }}
          </span>
          <button
            class="ml-4 be-btn primary lg danger"
            @click="$router.push({ name: 'assistance' })"
          >
            {{ $t("common.create_a_ticket") }}
          </button>
        </template>
        <button v-else class="ml-4 be-btn primary lg blue" @click="hideModal">
          {{ $t("common.close") }}
        </button>
      </template>
    </b-modal>
    <div v-if="isDetailsOpen" class="bg-secondary h-100">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { emptyListModel } from "@/helpers/constants";
import HeaderWithDivider from "../../components/common/HeaderWithDivider";
import BeTable from "../../components/BeTable";
import Loading from "../../components/common/Loading";
import NoContent from "../../components/common/NoContent";
import _ from "lodash";

export default {
  name: "TransferList",
  components: { HeaderWithDivider, BeTable, Loading, NoContent },
  data() {
    return {
      loading: true,
      transferField: {
        users_id: null,
        paginate: 10,
        search: false,
        keyword: null,
      },
      modalData: null,
      fields: [
        {
          label: this.$t("common.status"),
          key: "status",
        },
        {
          label: this.$t("common.date"),
          key: "created_at",
        },
        {
          label: this.$t("common.amount"),
          key: "amount",
        },
        {
          label: this.$t("common.description"),
          key: "desc",
        },
        {
          label: this.$t("common.reference"),
          key: "reference",
        },
        {
          label: "",
          key: "actions",
          class: "col-1 d-flex align-items-center justify-content-end",
        },
      ],
      smallTableFields: [
        {
          label: this.$t("common.status"),
          key: "status",
        },
        {
          label: this.$t("common.date"),
          key: "created_at",
        },
        {
          label: this.$t("common.amount"),
          key: "amount",
        },
        {
          label: this.$t("common.amount_status"),
          key: "montant-status",
        },
        {
          label: this.$t("common.reference"),
          key: "reference",
          class: "break-all",
        },
        {
          label: "Description",
          key: "desc",
        },
      ],
    };
  },

  computed: {
    ...mapState("transfer", ["metaTransfer"]),
    ...mapGetters("transfer", ["transfers", "current_page", "last_page"]),
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["isCurrentUser"]),
    ...mapState({
      sidebarState: state => state.sidebarState,
      totalPages: state => state.deposit.totalPages,
    }),
    isDetailsOpen() {
      return this.$route.name === "transfer.detail";
    },
    listModel() {
      return emptyListModel.transfer;
    },
    smallSize() {
      return this.$screen.width - (this.sidebarState ? 320 : 100) < 992;
    },
  },

  created() {
    this.transferField.users_id = this.user.id;
    this.getTransferList(1);
  },

  methods: {
    async getTransferList(page, cancel = false) {
      this.loading = true;
      if (this.transferField.keyword) this.transferField.search = true;
      else {
        // this.transferField.keyword = null;
        // this.transferField.search = false;
      }
      this.$store
        .dispatch("transfer/getTransferList", {
          page: page,
          cancel: cancel,
          field: this.transferField,
        })
        .then(() => {
          this.loading = false;
        });
    },
    cancelSearch() {
      this.transferField.keyword = null;
      if (this.transferField.search) {
        this.transferField.search = false;
        this.getTransferList(1, true);
      }
    },
    statusClass(status) {
      switch (status) {
        case "Succès":
          return "badge-success";
        case "Echec":
          return "badge-failure";
        case "En cours":
          return "badge-info";
      }
    },
    statusColorClass(status) {
      switch (status) {
        case "Succès":
          return "be-text-success";
        case "Echec":
          return "be-text-failure";
        case "En cours":
          return "be-text-encours";
      }
    },
    description(row) {
      if (this.isCurrentUser(row.receiver.id))
        return {
          title: this.$t("common.received_from"),
          email: row.sender.email,
        };
      else
        return {
          title: this.$t("common.sent_to"),
          email: row.receiver.email,
        };
    },
    openModal(row) {
      this.modalData = row;
      this.$refs.detailsModal.show();
    },
    hideModal() {
      this.$refs.detailsModal.hide();
    },
    search: _.debounce(async function(text) {
      this.transferField.keyword = text;
      await this.getTransferList(1);
    }, 200),
  },
};
</script>

<style lang="scss" scoped>
.phone {
  display: inline-block;
  max-width: 155px;
}

.hovered:hover .icon-arrow-right {
  display: block;
}

.modal-title {
  color: #0b1527;
  font-weight: 600;
}

.icon-close {
  cursor: pointer;
}

.fermer {
  cursor: pointer;
}

/deep/ .modal-backdrop {
  background: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(3px) !important;
}

.contentTop {
  @media (max-width: 500px) {
    padding: 1rem !important;
  }
}
</style>
